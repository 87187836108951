
/*Fix InputLabel positioning on small inputs variants*/
.MuiInputLabel-outlined {
  transform: translate(12px, 10px) scale(1) !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -8px) scale(0.75) !important;
}

.absolute-center {
  position: absolute;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  left:50%;
  top:50%;
  transform: translate(-50%,-50%);
}